import { DocumentNode, gql, useQuery } from '@apollo/client';
import { FC, useEffect, useState } from 'react';
import { LegalsType } from '../../core/router';

export interface LegalsProps {
  legalType: LegalsType;
}

const GET_CGU = gql`
  query getContent {
    getTermsOfUse {
      content {
        title
        html
      }
      createdAt
    }
  }
`;

const GET_MENTIONS = gql`
  query getContent {
    getLegalNotice {
      content {
        title
        html
      }
      createdAt
    }
  }
`;
const GET_COOKIES = gql`
  query getContent {
    getCookiePolicy {
      content {
        title
        html
      }
      createdAt
    }
  }
`;
const GET_CONFIDENTIALITE = gql`
  query getContent {
    getConfidentialityPolicy {
      content {
        title
        html
      }
      createdAt
    }
  }
`;

const GET_XPO_ACCOUNT_POLICY = gql`
  query getContent {
    getXpoAccountPolicy {
      content {
        title
        html
      }
      createdAt
    }
  }
`;

const GET_XPO_CARD_POLICY = gql`
  query getContent {
    getXpoCardPolicy {
      content {
        title
        html
      }
      createdAt
    }
  }
`;
const GET_PRICING_DOCUMENTATION = gql`
  query getContent {
    getPricingDocumentation {
      content {
        title
        html
      }
      createdAt
    }
  }
`;
const GET_CGU_PROMO_CODE = gql`
  query getContent {
    getCguCodePromo {
      content {
        title
        html
      }
      createdAt
    }
  }
`;
const GET_CGU_PAYLEAD = gql`
  query getCguPaylead {
    getCguPaylead {
      content {
        title
        html
      }
      createdAt
    }
  }
`;
const GET_CONFIDENTIALITY_POLICY_PAYLEAD = gql`
  query getConfidentialityPolicyPaylead {
    getConfidentialityPolicyPaylead {
      content {
        title
        html
      }
      createdAt
    }
  }
`;

const queries: { [key in LegalsType]: { key: string; query: DocumentNode } } = {
  mentions: {
    key: 'getLegalNotice',
    query: GET_MENTIONS
  },
  cgu: {
    key: 'getTermsOfUse',
    query: GET_CGU
  },
  cookies: {
    key: 'getCookiePolicy',
    query: GET_COOKIES
  },
  confidentialite: {
    key: 'getConfidentialityPolicy',
    query: GET_CONFIDENTIALITE
  },
  xpoAccountPolicy: {
    key: 'getXpoAccountPolicy',
    query: GET_XPO_ACCOUNT_POLICY
  },
  xpocardPolicy: {
    key: 'getXpoCardPolicy',
    query: GET_XPO_CARD_POLICY
  },
  pricingDocumentation: {
    key: 'getPricingDocumentation',
    query: GET_PRICING_DOCUMENTATION
  },
  cguCodePromo: {
    key: 'getCguCodePromo',
    query: GET_CGU_PROMO_CODE
  },
  cguPaylead: {
    key: 'getCguPaylead',
    query: GET_CGU_PAYLEAD
  },
  confidentialityPolicyPaylead: {
    key: 'getConfidentialityPolicyPaylead',
    query: GET_CONFIDENTIALITY_POLICY_PAYLEAD
  }
};

const Legals: FC<LegalsProps> = ({ legalType }) => {
  const [content, setContent] = useState('');
  const { loading, data } = useQuery(queries[legalType].query);

  useEffect(() => {
    if (!loading) {
      setContent(data[queries[legalType].key].content.html);
    }
  }, [loading, data]);

  return <div dangerouslySetInnerHTML={{ __html: content }} />;
};

export default Legals;
